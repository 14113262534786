@charset "UTF-8";
/* 共通 */
.App {
  min-height: 100%;
}

#root {
  height: 100%;
}

html {
  height: 100%;
}

body {
  font-family: 'Noto Sans JP', sans-serif;
  height: 100%;
}

a {
  text-decoration: none;
  color: -webkit-link;
  cursor: pointer;
}

a:hover {
  text-decoration: underline;
  opacity: 0.5;
}

a.clicked {
  color: #551a8b;
}

.innerBox {
  background-color: #e9eaed;
  padding: 50px 70px;
}

.innerBoxForTable {
  background-color: #e9eaed;
  padding: 50px 40px;
}

h2.title {
  color: #131d34;
  text-align: center;
  font-weight: 900;
  position: relative;
  padding: 0 0 0.6em 0;
  margin-bottom: 50px;
  margin-top: 100px;
  font-size: 2rem;
}

h2.title::before {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -41px;
  content: '';
  width: 40px;
  height: 4px;
  background-color: #2498b3;
  border-radius: 4px 0 0 4px;
}

h2.title::after {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-right: 1px;
  content: '';
  width: 40px;
  height: 4px;
  background-color: #a2d729;
  border-radius: 0 4px 4px 0;
}

.main-color {
  color: #2498b3;
}

.mg-b-10 {
  margin-bottom: 10px;
}

.mb-b-30 {
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .innerBox {
    padding: 40px 20px;
  }

  .flexB {
    display: block;
  }

  h2.title {
    margin: 20px;
    font-size: 1.5rem;
  }
}
